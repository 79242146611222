<template>
  <div class="seaContainer">
    <div class="textxxx" v-if="level == 'admin' && !isMobile">
      <h1 style="color: white">Ops, você não tem acesso a essa página</h1>
      <h5 style="color: white">Infelizmente esta página não está inclusa no seu plano</h5>
    </div>
    <div class="textxxx" v-if="level == 'admin' && isMobile">
      <h5 style="color: white">Ops, você não tem acesso a essa página</h5>
      <p style="color: white; font-size: small;">Infelizmente esta página não está inclusa no seu plano</p>
    </div>
    <div class="textxxx" v-if="level !== 'admin' && !isMobile">
      <h1 style="color: white">Ops, você não tem acesso a essa página</h1>
      <h5 style="color: white">Infelizmente esta página não está inclusa no seu plano/posição</h5>
    </div>
    <div class="textxxx" v-if="level !== 'admin' && isMobile">
      <h5 style="color: white">Ops, você não tem acesso a essa página</h5>
      <p style="color: white; font-size: small;">Infelizmente esta página não está inclusa no seu plano/posição</p>
    </div>
    <div class="submarine__container">
      <div class="light"></div>
      <div class="submarine__periscope"></div>
      <div class="submarine__periscope-glass"></div>
      <div class="submarine__sail">
        <div class="submarine__sail-shadow dark1">
        </div>
        <div class="submarine__sail-shadow light1"></div>
        <div class="submarine__sail-shadow dark2"></div>
      </div>
      <div class="submarine__body">
        <div class="submarine__window one">

        </div>
        <div class="submarine__window two">

        </div>
        <div class="submarine__shadow-dark"></div>
        <div class="submarine__shadow-light"></div>
        <div class="submarine__shadow-arcLight"></div>
      </div>
      <div class="submarine__propeller">
        <div class="propeller__perspective">
        <div class="submarine__propeller-parts darkOne"></div>
        <div class="submarine__propeller-parts lightOne"></div>
        </div>        
      </div>
    </div>
    <div class="bubbles__container">
      <span class="bubbles bubble-1"></span>
      <span class="bubbles bubble-2"></span>
      <span class="bubbles bubble-3"></span>
      <span class="bubbles bubble-4"></span>
    </div>
    <div class="white-button">
      <div >
        <BaseButton @click="redirect()" variant="outline-white">
          Voltar para a página principal
        </BaseButton>
      </div>
      <div v-if="client.width > 800" >
        <BaseButton @click="redirectPlanos()" variant="outline-white" v-if="level == 'admin'">
          Conheça os planos
        </BaseButton>
      </div>
    </div>
    <div class="ground__container">
      <div class="ground ground1">
        <span class="up-1"></span>
        <span class="up-2"></span>
        <span class="up-3"></span>
        <span class="up-4"></span>
        <span class="up-5"></span>
        <span class="up-6"></span>
        <span class="up-7"></span>
        <span class="up-8"></span>
        <span class="up-9"></span>
        <span class="up-10"></span>
      </div>
      <div class="ground ground2">
        <span class="up-1"></span>
        <span class="up-2"></span>
        <span class="up-3"></span>
        <span class="up-4"></span>
        <span class="up-5"></span>
        <span class="up-6"></span>
        <span class="up-7"></span>
        <span class="up-8"></span>
        <span class="up-9"></span>
        <span class="up-10"></span>
        <span class="up-11"></span>
        <span class="up-12"></span>
        <span class="up-13"></span>
        <span class="up-14"></span>
        <span class="up-15"></span>
        <span class="up-16"></span>
        <span class="up-17"></span>
        <span class="up-18"></span>
        <span class="up-19"></span>
        <span class="up-20"></span>
      </div>
    </div>
    <div class="midias" v-if="!isGreenn">
      <a href="https://www.facebook.com/gdigitalbr/" target="_blank">      
        <img
          class="img-icon"
          src="@/assets/icons/facebookNew.svg"
          alt="icon"
        />
      </a>
      <a href="https://www.instagram.com/gdigitaloficial/" target="_blank">
        <img
          class="img-icon"
          src="@/assets/icons/instagram.svg"
          alt="icon"
        />
      </a>
      <a href="https://www.youtube.com/channel/UClETYeE-kuDLqFZxZeCXEsg" target="_blank">
        <img
          class="img-icon"
          src="@/assets/icons/youtube.svg"
          alt="icon"
        />
      </a>
    </div>
    <div class="logo" v-if="isGreenn">
      <a @click="redirect()"><img src="@/assets/greenn_sales/GREENN_SALES_4.png" style="
    width: 136px;"/></a>
    </div>
    <div class="logo" v-else>
      <a @click="redirect()"><img src="@/assets/logo-gdigital-branca.png" /></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recursos: this.$store.getters.recursos,
      level: this.$store.getters.user.user.level,
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    redirect() {
      this.$router.push("/dynamicRoute/home");
    },
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    redirectPlanos() {
      window.location.href = 'https://gdigit.al/planos-2021/'
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  
};
</script>

<style lang="scss" scoped>

.seaContainer .logo {
  position: absolute;
  top: 95%;
  display: flex;
  left: 40px;
  transform: translate(-0%, -50%);
  cursor: pointer;
}
.seaContainer .textxxx {
  position: absolute;
  top: 23%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: white !important;
}
.white-button {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 20px;
  display: flex;
  cursor: pointer;
}

.midias {
  position: absolute;
  top: 95%;
  display: flex;
  right: 40px;
  transform: translate(-0%, -50%);
  width: max-content;
  gap: 20px;
  img{
    width: 25px;
    cursor: pointer;
  }
}

//colors
$bgColor: #130560;
$subMarineColor: #08d195;
$lightShadowColor: #159770;
$lightShadowColor2: #0daa7b;
$lightShadowColor3: #2ee7b0;
$darkShadowColor: #105f47;
$periscopeColor: #F0F78B;
$propellerColor: #f7e349;
$propellerColor2: #f7ac08;
$windowLightColor: #c9e5d9;
$lightColor: #817E97;
$seaGroundColor1:#0c0051;
$seaGroundColor2:#08003b;
//sizes

html,body {
  background:#130560;
  overflow:hidden;
}
.seaContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #130560;
  overflow:hidden;
}
.submarine__container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 200px;
  transform: translate(-50%, -50%);
}
.submarine__body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 80px;
  background: $subMarineColor;
  border-radius: 50px;
  transform: translate(-50%, -50%);
}
.submarine__propeller {
  position: absolute;
  left: 80%;
  top: 50%;
  width: 30px;
  height: 50px;
  transform: translate(0%, -50%);
  perspective: 600px;
 
}
.propeller__perspective
{
   width: 100%;
  height: 100%;
  transition: transform 1s;
 
  transform-style: preserve-3d;
  animation:rotateInfinite 1s linear infinite;
  cursor: pointer;
}
.submarine__propeller-parts
{
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
  top: 0%;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.darkOne {
  top:0%;
  background: $propellerColor2;
  transform: rotateY(180deg)rotateX(225deg)
}
.lightOne
{
  top:0%;
   background: $propellerColor;
  transform:rotateX(45deg);
}
.submarine__sail {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 90px;
  height: 50px;
  transform: translate(-50%, -100%);
  background: $lightShadowColor2;
  clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
}

.submarine__sail-shadow {
  position: absolute;
  width: 160%;
  height: 10%;
  background: $darkShadowColor;
  border-radius: 10px;
}
.dark1 {
  left: 0%;
  top: 0%;
  transform: translate(0%, -33%);
}
.dark2
{
  left:0%;
  top:50%;
}
.light1{
  left:20%;
  top:20%;
  width:50%;
  background:$lightShadowColor;
}
.submarine__window {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-image: linear-gradient(45deg,$windowLightColor,#fff);
  border: 8px solid $darkShadowColor;
  z-index:10;
  animation:shadow-change 1s linear infinite;
}
.one,
.two {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.one {
  left: 40%;
}
.two {
  left: 20%;
}

.submarine__shadow-dark {
  position: absolute;
  left: 70%;
  top: 50%;
  width: 70px;
  height: 10px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background: $darkShadowColor;
}
.submarine__shadow-light {
  position: absolute;
  left: 35%;
  top: 13%;
  width: 100px;
  height: 6px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background: $lightShadowColor;
}
.submarine__shadow-arcLight {
  position: absolute;
  top: 65%;
  left: 80%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $lightShadowColor;
}
.submarine__periscope {
  position: absolute;
  top: 0%;
  left: 40%;
  width: 20px;
  height: 50px;
  border-right: 10px solid $lightShadowColor3;
  border-top: 10px solid $lightShadowColor2;
  border-left: 0px solid transparent;
  border-bottom: 0px solid transparent;
  border-top-right-radius: 10px;
}
.submarine__periscope-glass {
  position: absolute;
  left: 40%;
  top: 0%;
  width: 5px;
  height: 15px;
  background: $propellerColor2;
  transform: translate(-50%, -15%);
}
.light {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 500px;
  height: 100px;
  background: linear-gradient(to left, $lightColor, $bgColor);
  /* The points are: centered top, left bottom, right bottom */
  clip-path: polygon(0% 0%, 50% 45%, 50% 55%, 0% 100%);
  
  transform: translate(-18%, -45%);
}
.bubbles__container
{
  position:absolute;
  top:50%;
  left:55%;
  width:100px;
  height:50px;
transform:translate(100%,-50%);
}
.bubbles
{
  position:absolute;
  width:10px;
  height:10px;
  border-radius:100%;
  left:5%;
  top:5%;
  background:#fff;
  opacity:0.8;
}
$bubble-class: bubble;

@for $i from 1 through 4{
  .#{$bubble-class}-#{$i}{
    top:15%*($i+1-1);
    left:1%;
    opacity: 0;
    animation:move-right 1s infinite linear;
    animation-delay: 0.25s*$i;
}
}
.ground__container
{
  position:absolute;
  top:75%;
  left:0%;
  width:100%;
  height:25%;
  background:$seaGroundColor2;
  margin-top:20px;
}
.ground1
{
  top:75%;
  height:100%;
   background:$seaGroundColor1;
}
.ground2
{
  position:absolute;
  top:35%;
  width:100%;
  height:100%;
  background:$seaGroundColor2;
}

.ground span
{
  position:absolute;
  width:60px;
  height:60px;
  border-radius:100%;
  
}
.ground1 span
{
   background:$seaGroundColor1;
}
.ground2 span
{
   background:$seaGroundColor2;
   width:80px;
  height:80px;
  border-radius:100%;
 transform:translateY(30%);
}

$ground-class: up;
@for $i from 1 through 20 
{
  .#{$ground-class}-#{$i}{
    left:-10%*($i+-1);
    top:-20px*($i*0.10);
    animation:moveThegroundRight $i+2s infinite linear;
}
}
//animation

@keyframes shadow-change
{
  0%,100%
  {
    background-image: linear-gradient(45deg,$windowLightColor 0%,$windowLightColor 20% ,#fff 21%, #fff 39%, $windowLightColor 40%,#fff 41%,#fff 59%,$windowLightColor 60%);
  }
  20%
  {
    background-image: linear-gradient(45deg,$windowLightColor 20%,$windowLightColor 40% ,#fff 41%, #fff 59%, $windowLightColor 60%,#fff 61%,#fff 79%,$windowLightColor 80%);
  }
  40%
  {
    
    background-image: linear-gradient(45deg,$windowLightColor 40%,$windowLightColor 60% ,#fff 61%, #fff 79%, $windowLightColor 80%,#fff 81%,#fff 99%,$windowLightColor 0%);
  }
  60%
  {
    background-image: linear-gradient(45deg,$windowLightColor 60%,$windowLightColor 80% ,#fff 81%, #fff 99%, $windowLightColor 0%,#fff 1%,#fff 19%,$windowLightColor 20%);
  }
  80%
  {
    background-image: linear-gradient(45deg,$windowLightColor 80%,$windowLightColor 0% ,#fff 1%, #fff 19%, $windowLightColor 20%,#fff 21%,#fff 39%,$windowLightColor 40%);
  }
  
}

@keyframes move-right {
  0%
  {
    opacity:0;
  }
  10%
  {
    opacity:0.4;
    transform:translate(10%,10%);
  }
  50%
  {
    opacity:0.2;
    transform:translate(450%,25%);
  }
  80%
  {
    opacity:0;
     transform:translateX(555%);
  }
  100%
  {
    opacity:0;
    left:0%;
    top:0%;
  }
}
@keyframes rotateInfinite{
  0%
  {
    transform: rotateX(0deg);
  }
  50%
  {
    transform: rotateX(180deg);
  }
  100%
  {
    transform: rotateX(360deg);
  }
}
@keyframes moveThegroundRight
{
 90%
  {
    opacity:1;
   left:100%;
  }
  95%,100%
  {
    left:1050%;
    opacity:0;
  }
}
</style>